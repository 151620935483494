<template>
  <div class="bindPhone_box">
    <div class="bindPhone_tips">
      <p class="tips">{{$t('toast.findAccountTxt1')}}</p>
    </div>
    <ul class="ft_bindPhone_box">
      <li class="bindPhone_item ">
        <input type="text"
               class="verify_input"
               :placeholder="$t('toast.findAccountPlaceHolder')"
               v-model="account">
      </li>
      <li class=" bindPhone_submit"
          @click="queryPhone">
        {{$t('toast.comfirmBtn1')}}
      </li>
    </ul>
  </div>
</template>

<script>
import { queryPhone } from '@/api/login'
import { setSessionStorage, getSessionStorage } from '@/utils/stage'
export default {
  data() {
    return {
      account: '',
    }
  },
  methods: {
    queryPhone() {
      const configData = JSON.parse(getSessionStorage('message'))
      if (this.account === '') {
        this.$toast(this.$t('toast.findAccountTxt1'))
        return
      }
      const mailReg =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      const phoneReg =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/
      const accountReg = /^[a-zA-Z]\w{3,17}$/
      if (
        !mailReg.test(this.username) &&
        !phoneReg.test(this.username) &&
        !accountReg.test(this.username)
      ) {
        this.$toast(this.$t('toast.verifypAccount'))
        return
      }
      const msg = Object.assign(JSON.parse(JSON.stringify(configData)), {
        account: this.account,
      })
      queryPhone(msg).then((res) => {
        setSessionStorage('accountInfo', JSON.stringify(res.data))
        setSessionStorage('loginAccount', this.account)
        this.$router.push({ path: '/sendPhone' })
      })
    },
  },
}
</script>
<style lang="scss" scope>
@media (min-width: 1600px) {
  .bindPhone_box {
    width: 700px;
    height: 500px;
    background: #fff;
    margin: 0 auto;
    padding: 60px;
    padding-bottom: 200px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33px;
        color: #575757;
      }
      .userPhone {
        font-size: 30px;
        margin-top: 24px;
        margin-bottom: 50px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60px;
        border: 1px solid #cfcfcf;
        opacity: 1;
        border-radius: 6px;
        margin-top: 15px;
        padding: 10px 34px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6px;
        letter-spacing: 0px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200px;
        position: relative;
        .verify_input {
          border-right: 1px solid #cfcfcf;
        }
        .verify_btn {
          width: 200px;
          outline: none;
          height: 60px;
          line-height: 60px;
          font-size: 26px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .bindPhone_box {
    width: 700 * 0.85px;
    height: 500 * 0.85px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.85px;
    padding-bottom: 200 * 0.85px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22 * 0.85px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33 * 0.85px;
        color: #575757;
      }
      .userPhone {
        font-size: 30 * 0.85px;
        margin-top: 24 * 0.85px;
        margin-bottom: 50 * 0.85px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60 * 0.85px;
        border: 1 * 0.85px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.85px;
        margin-top: 15 * 0.85px;
        padding: 10 * 0.85px 34 * 0.85px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.85px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50 * 0.85px;
        height: 60 * 0.85px;
        line-height: 60 * 0.85px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.85px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.85px;
        letter-spacing: 0 * 0.85px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.85px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.85px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.85px;
          outline: none;
          height: 60 * 0.85px;
          line-height: 60 * 0.85px;
          font-size: 26 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
@media (min-width: 500px) and (max-width:1199px) {
  .bindPhone_box {
    width: 700 * 0.7px;
    height: 500 * 0.7px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.7px;
    padding-bottom: 200 * 0.7px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22 * 0.7px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33 * 0.7px;
        color: #575757;
      }
      .userPhone {
        font-size: 30 * 0.7px;
        margin-top: 24 * 0.7px;
        margin-bottom: 50 * 0.7px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60 * 0.7px;
        border: 1 * 0.7px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.7px;
        margin-top: 15 * 0.7px;
        padding: 10 * 0.7px 34 * 0.7px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.7px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50 * 0.7px;
        height: 60 * 0.7px;
        line-height: 60 * 0.7px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.7px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.7px;
        letter-spacing: 0 * 0.7px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.7px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.7px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.7px;
          outline: none;
          height: 60 * 0.7px;
          line-height: 60 * 0.7px;
          font-size: 26 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
@media screen and (max-width:499px) {
  .bindPhone_box {
    width: 380px;
    height: 500 * 0.6px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.6px;
    padding-bottom: 200 * 0.6px;
    box-sizing: border-box;
    .bindPhone_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22 * 0.6px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33 * 0.6px;
        color: #575757;
      }
      .userPhone {
        font-size: 30 * 0.6px;
        margin-top: 24 * 0.6px;
        margin-bottom: 50 * 0.6px;
      }
    }
    .ft_bindPhone_box {
      .bindPhone_item {
        width: 100%;
        height: 60 * 0.6px;
        border: 1 * 0.6px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.6px;
        margin-top: 15 * 0.6px;
        padding: 10 * 0.6px 34 * 0.6px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.6px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.7px solid #000;
        }
      }
      .bindPhone_submit {
        margin-top: 50 * 0.6px;
        height: 60 * 0.6px;
        line-height: 60 * 0.6px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.6px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.6px;
        letter-spacing: 0 * 0.6px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.6px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.6px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.6px;
          outline: none;
          height: 60 * 0.6px;
          line-height: 60 * 0.6px;
          font-size: 26 * 0.6px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
</style>
